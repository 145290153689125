import { Apis, get, post, patch, deleted, put } from '.';

export const Service = {
	// *Auth
	login: async (obj) => {
		const result = await post(Apis.login, obj);
		if (result.status === 200) return result.data;
		throw result;
	},
	register: async (obj) => {
		const result = await post(Apis.register, obj);
		if (result.status === 200) return result.data;
		throw result;
	},
	verifyToken: async (token) => {
		const result = await post(Apis.verifyToken, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getPasswordResetLink: async (obj) => {
		const result = await post(Apis.getPasswordResetLink, obj);
		if (result.status === 200) return result.data;
		throw result;
	},
	resetPassword: async (obj) => {
		const result = await post(Apis.resetPassword, obj);
		if (result.status === 200) return result.data;
		throw result;
	},
	checkUserEmailAndPhone: async (obj) => {
		const result = await post(Apis.checkUserEmailAndPhone, obj);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *User Notifications
	getUserNotification: async (token, limit, page) => {
		const result = await get(`${Apis.getUserNotifications}?limit=${limit}&page=${page}`, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getUserNotificationUnreadCount: async (token) => {
		const result = await get(Apis.getUserNotificationUnreadCount, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	postReadNotification: async (obj, token) => {
		const result = await post(Apis.updateReadNotification, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	postClearNotifications: async (token) => {
		const result = await post(Apis.clearNotifications, {}, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *Daily Quotes
	getDailyQuote: async (token) => {
		const result = await get(Apis.getDailyQuote, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *Tasks
	addTask: async (obj, token) => {
		const result = await post(Apis.addTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	addSubTask: async (obj, token) => {
		const result = await post(Apis.addSubTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	editSubTask: async (obj, token) => {
		const result = await patch(Apis.editSubTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getUserTask: async (token) => {
		const result = await get(Apis.getUserTask, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getUserSubTaskByTaskId: async (taskId, token) => {
		const result = await get(`${Apis.getUserSubTaskByTaskId}?id=${taskId}`, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	editTask: async (obj, token) => {
		const result = await patch(Apis.editTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	checkUncheckSubtask: async (obj, token) => {
		const result = await patch(Apis.checkUncheckSubtask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	clearCompletedSubTasks: async (obj, token) => {
		const result = await post(Apis.clearCompletedSubTasks, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	deleteTask: async (obj, token) => {
		const result = await deleted(Apis.deleteTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	swapTask: async (obj, token) => {
		const result = await patch(Apis.swapTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	swapSubTask: async (obj, token) => {
		const result = await patch(Apis.swapSubTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	deleteSubTask: async (obj, token) => {
		const result = await deleted(Apis.deleteSubTask, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *Reminder
	addReminder: async (obj, token) => {
		const result = await post(Apis.addReminder, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	editReminder: async (obj, token) => {
		const result = await put(Apis.editReminder, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	deleteReminder: async (obj, token) => {
		const result = await deleted(Apis.deleteReminder, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getReminderDetail: async (token, id) => {
		const result = await get(`${Apis.getReminderDetail}/${id}`, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	// *Thoughts
	addThought: async (obj, token) => {
		const result = await post(Apis.addThought, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getThought: async (token) => {
		const result = await get(Apis.getThought, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getThoughtByThoughtId: async (id, token) => {
		const result = await get(`${Apis.getThoughtByThoughtId}?id=${id}`, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	editThought: async (obj, token) => {
		const result = await patch(Apis.editThought, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	deleteThought: async (obj, token) => {
		const result = await deleted(Apis.deleteThought, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *Setting
	updateSetting: async (obj, token) => {
		const result = await patch(Apis.updateSetting, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	deleteAccount: async (obj, token) => {
		const result = await patch(Apis.deleteAccount, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *Colors
	getColors: async (token) => {
		const result = await get(Apis.getColors, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// *Stripe
	getSecretKey: async (token) => {
		const result = await get(Apis.getSecretKey, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	subscription: async (obj, token) => {
		const result = await post(Apis.subscription, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	freeTrial: async (obj, token) => {
		const result = await post(Apis.freeTrial, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// User
	getCurrentUser: async (token) => {
		const result = await get(Apis.getCurrentUser, token);
		if (result.status === 200) return result.data;
		throw result;
	},

	// Payment
	getPlan: async (token) => {
		const result = await get(Apis.getPlan, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	getPaymentMethod: async (token) => {
		const result = await get(Apis.getPaymentMethod, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	setupPayment: async (obj, token) => {
		const result = await post(Apis.setupPayment, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
	createPaymentIntent: async (obj, token) => {
		const result = await post(Apis.createPaymentIntent, obj, token);
		if (result.status === 200) return result.data;
		throw result;
	},
};
