import { makeAutoObservable, reaction } from 'mobx';
import tokenStore from './TokenStore';
import { Service } from '../config/service';

class AuthStore {
	user = null;

	isAuthenticated = false;

	constructor() {
		makeAutoObservable(this);

		this.isAuthenticated = localStorage.getItem('isAuthenticated') === 'true' || false;

		reaction(
			() => this.isAuthenticated,
			(isAuthenticated) => {
				if (isAuthenticated) {
					localStorage.setItem('isAuthenticated', String(isAuthenticated));
				} else {
					localStorage.removeItem('isAuthenticated');
				}
			}
		);
	}

	loadUserProfile() {
		return Service.getCurrentUser(tokenStore.token).then((res) => {
			if (res?.status) {
				this.user = res.data;
			}
			return res;
		});
	}

	logout() {
		this.user = null;
		tokenStore.logout();
		this.isAuthenticated = false;
	}

	setIsAuthenticated(isAuthenticated) {
		this.isAuthenticated = isAuthenticated;
	}

	isExpiredSubscription() {
		const { subscription } = this.user;
		if (!subscription) {
			return true;
		}

		const { status, ended_at, trial_end } = subscription;
		if (status !== 'active' && status !== 'trialing') {
			return true;
		}
		const now = Date.now();
		return (ended_at && ended_at < now) || (trial_end && trial_end < now);
	}

	statusPayment() {
		const { paymentIntent } = this.user;
		if (!paymentIntent) {
			return null;
		}
		return paymentIntent.status;
	}
}

export default new AuthStore();
