import io from 'socket.io-client';

export const socketConfig = (token) => {
	var socket = io(process.env.REACT_APP_SOCKET_URL, {
		path: process.env.REACT_APP_SOCKET_PATH,
		withCredentials: true,
		rejectUnauthorized: false,
		reconnection: true,
		transports: ['websocket'],
		auth: {
			token,
		},
	});

	return socket;
};
