import React from 'react';

function WarningIcon({ width = 32, height = 32 }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox={`0 0 ${width} ${height}`}
		>
			<g
				stroke="#8CC6C2"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
				clipPath="url(#clip0_1_798)"
			>
				<path d="M16 12v5.333M13.817 4.788L3.01 22.833a2.552 2.552 0 002.182 3.828h21.618a2.551 2.551 0 002.182-3.826L18.183 4.787a2.552 2.552 0 00-4.366 0v.001zM16 21.333h.013"></path>
			</g>
			<defs>
				<clipPath id="clip0_1_798">
					<path fill="#fff" d="M0 0H32V32H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default WarningIcon;
