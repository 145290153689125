import React, { useEffect } from 'react';

import { disabledInspect } from 'utils';

import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { images } from 'assets/images/icons';

export default function Deleted(props) {
	const { open, onClose, id, deleted } = props;

	useEffect(() => {
		disabledInspect();
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			disableEscapeKeyDown
			disableBackdropClick
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
			classes={{
				paper: 'paper-dialog-delete-task',
			}}
		>
			<DialogContent>
				<div className="dialog-delete-list-content">
					<img src={images.trashIcon} alt="icon trash" width="24px" height="24px" />
					<div className="dialog-delete-list-title">Delete List</div>
					<div className="dialog-delete-list-sub">
						Deleting this list will permanently delete all tasks within
						<br />
						it. Would you like to proceed?
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Grid container spacing={0} justifyContent="center" alignItems="center">
					<Grid item xs={12} sm={5} md={6}>
						<button
							type="button"
							className="button-stroke dialog-button-stroke btn-action"
							onClick={onClose}
						>
							CANCEL
						</button>
					</Grid>
					<Grid item xs={12} sm={5} md={6}>
						<button
							type="button"
							className="button-raised btn-action"
							onClick={() => {
								deleted(id);
							}}
						>
							DELETE
						</button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
