import React from 'react';

function CheckedIcon({ width = 20, height = 20, color = '#8CC6C2' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox={`0 0 ${width} ${height}`}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M0 10C0 4.477 4.477 0 10 0A10 10 0 110 10zm9.73 3.61l4.57-6v-.03a1.006 1.006 0 00-1.6-1.22l-3.78 5-1.63-2.08a1.001 1.001 0 00-1.58 1.23l2.44 3.11a1 1 0 001.58-.01z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

export default CheckedIcon;
