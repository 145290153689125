import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Logo } from 'assets/images/icons';
import { convertToCurrency, disabledInspect } from 'utils/index';
import { Service } from 'config/service';

import { Box, CircularProgress, Dialog, DialogContent, Grid, Radio, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// *Import Components
import Toaster from 'components/toaster';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useStore } from '../../context/AppContext';
import PaymentForm from './components/PaymentForm';
import AddPaymentMethodForm from './components/AddPaymentMethodForm';
import { observer } from 'mobx-react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Payment() {
	const history = useHistory();
	const { authStore } = useStore();
	const [priceInfo, setPriceInfo] = useState(null);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [messageLoading, setMessageLoading] = useState('Processing Payment...');
	// *For Form Validation
	const { handleSubmit } = useForm();

	const [selectType, setSelectType] = useState(1);

	const [selectPay, setSelectPay] = useState('pay');

	const onHandleCallBack = (data) => {
		if (Object.hasOwn(data, 'isProcessing')) {
			setLoadingSubmit(data.isProcessing);
		}
	};

	const onHandleAddCardCallBack = (data) => {
		if (Object.hasOwn(data, 'isProcessing')) {
			setMessageLoading('Processing add payment method...');
			if (data.isProcessing) {
				setLoadingSubmit(true);
			} else {
				setTimeout(() => {
					setLoadingSubmit(false);
				}, 2000);
			}
		}
		if (Object.hasOwn(data, 'status')) {
			setTimeout(() => {
				onSubmitStartTrial().then();
			}, 2000);
		}
	};

	const onSubmitStartTrial = async () => {
		try {
			let oldToken = localStorage.getItem('jwt');
			let obj = {};
			const { status } = await Service.freeTrial(obj, oldToken);
			if (status === true) {
				await authStore.loadUserProfile();
				history.push('/my-missions');
			}
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
		}
	};

	const selectPaymentType = async () => {
		try {
			if (selectPay === 'pay') {
				setSelectType(2);
			} else if (authStore?.user?.customer?.default_payment_method) {
				onSubmitStartTrial().then();
			} else {
				setSelectType(3);
			}
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
		}
	};

	const getPlan = async () => {
		try {
			const token = localStorage.getItem('jwt');
			const { data } = await Service.getPlan(token);
			if (data && data.length > 0) {
				const product = data[0];
				const price = product?.prices[0];
				setPriceInfo(price);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const options = useMemo(() => {
		return {
			mode: 'payment',
			amount: priceInfo?.unit_amount || 990,
			currency: priceInfo?.currency || 'usd',
			setup_future_usage: 'off_session',
			appearance: {
				theme: 'stripe',
				labels: 'floating',
				variables: {
					colorPrimary: '#419488',
					colorBackground: '#ffffff',
					colorText: '#30313d',
					colorDanger: '#df1b41',
					fontFamily: 'Ideal Sans, system-ui, sans-serif',
					borderRadius: '4px',
				},
				// link: {
				// 	enabled: false,
				// },
				rules: {
					'.Tab': {
						border: '1px solid #D9DDE2',
						boxShadow: 'none',
					},
					'.Tab--selected': {
						border: '1px solid #419488',
						boxShadow: 'none',
					},
					'.Tab:focus': {
						border: '1px solid #419488',
						boxShadow: 'none',
					},
					'.Tab--selected:focus': {
						border: '1px solid #419488',
						boxShadow: 'none',
					},
					'.TabIcon--selected': {
						fill: '#419488',
					},
					'.TabLabel--selected': {
						color: '#419488',
					},
					'.Input:focus': {
						boxShadow: 'none',
					},
					'.TermsText': {
						display: 'none',
						color: '#419488',
					},
					'.Error': {
						fontSize: '12px',
					},
					'.Input--invalid': {
						boxShadow: 'none',
					},
				},
			},
			layout: {
				type: 'tabs',
				defaultCollapsed: false,
			},
			// paymentMethodTypes: ['card'],
		};
	}, [priceInfo]);

	const optionsAddCard = {
		mode: 'setup',
		appearance: {
			theme: 'stripe',
			labels: 'floating',
			variables: {
				colorPrimary: '#419488',
				colorBackground: '#ffffff',
				colorText: '#30313d',
				colorDanger: '#df1b41',
				fontFamily: 'Ideal Sans, system-ui, sans-serif',
				borderRadius: '4px',
			},
			rules: {
				'.Tab': {
					border: '1px solid #D9DDE2',
					boxShadow: 'none',
				},
				'.Tab--selected': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.Tab:focus': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.Tab--selected:focus': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.TabIcon--selected': {
					fill: '#419488',
				},
				'.TabLabel--selected': {
					color: '#419488',
				},
				'.Input:focus': {
					boxShadow: 'none',
				},
				'.TermsText': {
					display: 'none',
					color: '#419488',
				},
				'.Error': {
					fontSize: '12px',
				},
				'.Input--invalid': {
					boxShadow: 'none',
				},
			},
		},
		layout: {
			type: 'tabs',
			defaultCollapsed: false,
		},
		paymentMethodTypes: ['card', 'au_becs_debit'],
	};

	useEffect(() => {
		disabledInspect();
		getPlan().then();
	}, []);

	useEffect(() => {
		if (authStore.user && authStore.user.subscription) {
			window.location.replace('/');
		}
	}, [authStore.user]);

	return (
		<div className="form-bg">
			<div className="form-wrapper">
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					{/* ========== Toaster ========== */}
					<Toaster />

					<div className="flex justify-center">
						<Logo />
					</div>

					<Grid item xs={12} sm={12} md={12}>
						{selectType === 1 ? (
							<form onSubmit={handleSubmit(selectPaymentType)}>
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Grid item sm={12} md={9}>
										<Fragment>
											<Typography variant="h2">
												<Typography
													variant="h2"
													component="span"
													style={{
														fontSize: '36px',
														fontFamily: 'Rockness',
														color: '#003361',
													}}
												>
													Pay
												</Typography>{' '}
												With Peace Of Mind
											</Typography>
											<Typography variant="body2" style={{ fontSize: '13px', color: '#646464' }}>
												Start using the app to get most out of your desire.
											</Typography>
										</Fragment>
										<Box style={{ margin: '30px 0px' }}>
											<Box
												onClick={() => setSelectPay('pay')}
												className={`payment-select-box ${selectPay === 'pay' && 'selected-payment-box'}`}
											>
												<Typography variant="body1">Per Month</Typography>
												<Typography variant="body1" className="price">
													{priceInfo ? convertToCurrency(priceInfo) : ''}
												</Typography>
												<Radio
													color="#003361"
													checked={selectPay === 'pay'}
													value="pay"
													name="radio-buttons"
													inputProps={{ 'aria-label': 'pay' }}
												/>
											</Box>
											<Box
												onClick={() => setSelectPay('trial')}
												className={`payment-select-box ${selectPay === 'trial' && 'selected-payment-box'}`}
											>
												<Typography variant="body1">14 Days</Typography>
												<Typography variant="body1" className="price">
													Free Trial
												</Typography>
												<Radio
													color="#003361"
													checked={selectPay === 'trial'}
													// onChange={(e) => setSelectPay(e.target.value)}
													value="trial"
													name="radio-buttons"
													inputProps={{ 'aria-label': 'trial' }}
												/>
											</Box>
										</Box>
										<button type="submit" className={`button-raised`}>
											Continue
										</button>
										<Typography
											variant="body2"
											style={{ fontSize: '13px', marginTop: '8px', color: '#fff' }}
										>
											By continuing you are agree to terms & conditions
										</Typography>
									</Grid>
								</Grid>
							</form>
						) : selectType === 2 ? (
							<Elements stripe={stripePromise} options={options}>
								<PaymentForm
									priceId={priceInfo?._id}
									returnUrl={`${window.location.origin}`}
									onCallBack={onHandleCallBack}
								/>
							</Elements>
						) : (
							<Elements stripe={stripePromise} options={optionsAddCard}>
								<AddPaymentMethodForm onCallBack={onHandleAddCardCallBack} />
							</Elements>
						)}
					</Grid>

					<Grid item xs={12} sm={12} md={12}>
						<Typography
							className="text-xs-center"
							component="p"
							onClick={() => {
								authStore.logout();
								history.push('/login');
							}}
						>
							<span className="cursor-pointer">Change account</span>
						</Typography>
					</Grid>
				</Grid>
			</div>

			<Dialog
				open={loadingSubmit}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<div className="paper-dialog-payment-loading">
						<CircularProgress color="inherit" />
						<div className="paper-dialog-payment-loading-title">{messageLoading}</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default observer(Payment);
