import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { disabledInspect } from 'utils/index';
import { Service } from 'config/service';

import { Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStore } from '../../context/AppContext';
import { observer } from 'mobx-react';

function WriteThought() {
	const { authStore, paymentStore } = useStore();

	const history = useHistory();

	// *For Loader
	const [loader, setLoader] = useState(false);

	// *For Form Validation
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	// *For Save Thought
	const save = async (data) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		setLoader(true);
		try {
			const token = localStorage.getItem('jwt');
			const obj = {
				title: data.title,
				description: data.description,
			};
			const { message } = await Service.addThought(obj, token);
			setTimeout(() => {
				history.push('/my-thoughts');
			}, 1000);
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		disabledInspect();
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<>
			{/* ========== Thought ========== */}
			<Grid item xs={12} sm={12} md={12} lg={12}>
				{/* ========== Breadcrumbs ========== */}
				<Breadcrumbs aria-label="breadcrumb">
					<Typography className="cursor-pointer" component="p" onClick={() => history.push('/my-thoughts')}>
						My Thoughts
					</Typography>
					<Typography className="text-color" component="p">
						Write Your Thoughts
					</Typography>
				</Breadcrumbs>

				{/* ========== Write Thought ========== */}
				<form className="write-thought" onSubmit={handleSubmit(save)}>
					<Grid container spacing={0} justifyContent="center">
						<Grid item xs={12} sm={12} md={12}>
							<input
								className="title"
								placeholder="Thought Title"
								{...register('title', {
									required: 'Title is required',
								})}
							/>
							{errors?.title?.message && <p className="error">{errors?.title?.message}</p>}
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<textarea
								className="description"
								placeholder="Description"
								{...register('description', {
									required: 'Thought is required',
								})}
							/>
							{errors?.description?.message && <p className="error">{errors?.description?.message}</p>}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<button
								type="submit"
								className={`button-raised ${loader === true ? 'spinner button-disabled ' : ''}`}
								disabled={loader === true}
							>
								Save
							</button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</>
	);
}

export default observer(WriteThought);
