import React from 'react';
import { Redirect, Route } from 'react-router';
import { useStore } from '../context/AppContext';
import { useLocation } from 'react-router-dom';

export default function PrivateRoute({ ...rest }) {
	const { authStore } = useStore();
	const location = useLocation();
	if (!authStore.isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: { from: location },
				}}
			/>
		);
	}

	return <Route {...rest} />;
}
