import Axios from '../axios';
import { errorHandler } from './errorHandler';

export const Apis = {
	// *Auth
	login: 'user/login',
	register: 'user/register',
	verifyToken: 'user/verifyToken',
	resetPassword: 'password-reset/resetPassword',
	getPasswordResetLink: 'password-reset/getPasswordResetLink',
	checkUserEmailAndPhone: 'user/checkUserEmailAndPhone',

	// *User Notification
	getUserNotifications: 'notification/user-notifications',
	getUserNotificationUnreadCount: 'notification//unread/count',
	updateReadNotification: 'notification/mark-as-read',
	clearNotifications: 'notification/mark-all-read',

	// *Daily Quotes
	getDailyQuote: 'quote/get-quote',

	// *Task
	addTask: 'task/add-task',
	addSubTask: 'subtask/add-subtask',
	editSubTask: 'subtask/update-subtask',
	getUserTask: 'task/user-tasks',
	getUserSubTaskByTaskId: 'subtask/subtask-by-task-id',
	editTask: 'task/update-title',
	checkUncheckSubtask: 'subtask/check-uncheck-subtask',
	clearCompletedSubTasks: 'subtask/clear-completed-subtasks',
	deleteTask: 'task/delete-task',
	swapTask: 'task/swap-task',
	swapSubTask: 'subtask/swap-subtask',
	deleteSubTask: 'subtask/delete-subtask',

	// *Reminder
	addReminder: 'reminder/add-reminder',
	editReminder: 'reminder/update-reminder',
	deleteReminder: 'reminder/delete-reminder',
	getReminderDetail: 'reminder/detail-reminder',

	// *Thought
	addThought: 'thought/add-thought',
	getThought: 'thought/get-thought',
	getThoughtByThoughtId: 'thought/get-thought-by',
	editThought: 'thought/update-thought',
	deleteThought: 'thought/delete-thought',

	// *Setting
	updateSetting: 'app-settings/updateSetting',
	deleteAccount: 'user/deactivateAccount',

	// *Colors
	getColors: 'color/get-colors',

	// *Stripe
	getSecretKey: 'plans/getSubscriptionKey',
	subscription: 'plans/finalizeSubscription',
	freeTrial: 'plans/startFreeTrial',

	//  User
	getCurrentUser: 'user/user-profile',

	// Payment
	getPlan: 'payment/plan',
	getPaymentMethod: 'payment/payment-method',
	setupPayment: 'payment/setup-payment',
	createPaymentIntent: 'payment/create-payment-intent',
};

export const headers = {
	'content-type': 'application/json',
};

export const get = async (data, token) => {
	try {
		const result = await Axios.get(data, { headers: { Authorization: `Bearer ${token}` } });
		return result;
	} catch (e) {
		throw errorHandler(e);
	}
};

export const post = async (endPoint, data, token) => {
	try {
		const result = await Axios.post(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
		return result;
	} catch (e) {
		throw errorHandler(e);
	}
};

export const put = async (endPoint, data, token) => {
	try {
		const result = await Axios.put(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
		return result;
	} catch (e) {
		throw errorHandler(e);
	}
};

export const patch = async (endPoint, data, token) => {
	try {
		const result = await Axios.patch(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
		return result;
	} catch (e) {
		throw errorHandler(e);
	}
};

export const deleted = async (endPoint, data, token) => {
	try {
		const result = await Axios.delete(endPoint, { headers: { Authorization: `Bearer ${token}` }, data });
		return result;
	} catch (e) {
		throw errorHandler(e);
	}
};
