import React, { useState } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FormCard from './FormCard';
import CloseIcon from '../../assets/images/icons/CloseIcon';
import MasterCardIcon from '../../assets/images/icons/MasterCardIcon';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddPaymentDialogDialog = ({ open, onClose, onSubmit, paymentMethod }) => {
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const options = {
		mode: 'setup',
		// currency: 'usd',
		// automatic_payment_methods: {
		//   enabled: true,
		// },
		appearance: {
			theme: 'stripe',
			labels: 'floating',
			variables: {
				colorPrimary: '#419488',
				colorBackground: '#ffffff',
				colorText: '#30313d',
				colorDanger: '#df1b41',
				fontFamily: 'Ideal Sans, system-ui, sans-serif',
				borderRadius: '4px',
			},
			rules: {
				'.Tab': {
					border: '1px solid #D9DDE2',
					boxShadow: 'none',
				},
				'.Tab--selected': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.Tab:focus': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.Tab--selected:focus': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.TabIcon--selected': {
					fill: '#419488',
				},
				'.TabLabel--selected': {
					color: '#419488',
				},
				'.Input:focus': {
					boxShadow: 'none',
				},
				'.TermsText': {
					display: 'none',
					color: '#419488',
				},
				'.Error': {
					fontSize: '12px',
				},
				'.Input--invalid': {
					boxShadow: 'none',
				},
			},
		},
		layout: {
			type: 'tabs',
			defaultCollapsed: false,
		},
		paymentMethodTypes: ['card', 'au_becs_debit'],
	};

	const onHandleCallBack = (data) => {
		if (Object.hasOwn(data, 'isProcessing')) {
			if (data.isProcessing) {
				setLoadingSubmit(true);
			} else {
				setTimeout(() => {
					setLoadingSubmit(false);
				}, 2000);
			}
		}
		if (Object.hasOwn(data, 'status')) {
			setTimeout(() => {
				onSubmit();
			}, 2000);
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				classes={{
					paper: 'add-payment-dialog',
				}}
			>
				<DialogContent>
					<div className="flex flex-col gap-4">
						<div className="flex flex-col">
							<div className="flex w-full justify-end">
								<button onClick={onClose}>
									<CloseIcon />
								</button>
							</div>
							<div className="text-primary text-[18px] font-black">
								{paymentMethod ? 'Change Payment Method' : 'Add Payment Method'}
							</div>
						</div>

						{paymentMethod && (
							<div className="flex justify-between items-center text-second gap-2">
								<div className="text-[14px] font-extrabold flex-none">Current:</div>
								<div className="flex flex-grow flex-row items-center gap-2 rounded-[11px] h-[32px] px-4 bg-[#EDF4F7]">
									<MasterCardIcon />
									<p className="text-second text-[12px] font-medium first-letter:uppercase">
										{paymentMethod?.display_brand || ''}
									</p>
									<p className="text-second text-[12px] font-medium">
										XXXX XXXX XXXX {paymentMethod?.last4 || ''}
									</p>
								</div>
							</div>
						)}

						<p className="text-second text-[14px] font-extrabold">New Payment Method:</p>

						<Elements stripe={stripePromise} options={options}>
							<FormCard onCallBack={onHandleCallBack} />
						</Elements>
					</div>
				</DialogContent>
			</Dialog>

			<Dialog
				open={loadingSubmit}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<div className="paper-dialog-payment-loading">
						<CircularProgress color="inherit" />
						<div className="paper-dialog-payment-loading-title">
							Processing add or change payment method...
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default AddPaymentDialogDialog;
