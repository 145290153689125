import React, { createRef, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { EditTask, images, More, Plus, DeleteTask, VerticalMenu } from 'assets/images/icons';
import { ClickAwayListener, Grid, IconButton, Menu, Typography } from '@material-ui/core';
import AddSubTask from 'components/add-subtask';
import { Service } from 'config/service';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import SubTask from './subtask';
import { useStore } from '../../context/AppContext';
import { observer } from 'mobx-react';

var taskId = '';

function ListItem(props) {
	const {
		item,
		getTask,
		index,
		subTask,
		editTaskDialog,
		deleteTaskDialog,
		clearTasksDialog,
		editTaskList,
		taskColor,
	} = props;
	const { authStore, paymentStore } = useStore();

	const textInput = useRef(null);

	// *For Text Truncate
	const [textTruncate, setTextTruncate] = useState('');

	// *For Menu
	const [anchorEl, setAnchorEl] = useState(null);
	const [subTasks, setSubTasks] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [editEnable, setEditEnable] = useState(false);
	const formRef = useRef(null);

	// *For Sub Task
	const [openAddSubTask, setOpenAddSubTask] = useState(false);
	const [addSubTaskHandle, setAddSubTaskHandle] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		reset,
	} = useForm();

	// *For Menu Open and Close
	const menuHandler = (index, event) => {
		setAnchorEl({ [index]: event.currentTarget });
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// *For Text Truncate
	const truncateHandler = (id) => {
		try {
			if (id === textTruncate) {
				setTextTruncate('');
			} else {
				setTextTruncate(id);
			}
		} catch (error) {
			console.log('file: ListItem.js => line 36 => truncateHandler => error', error);
		}
	};

	const onDragEnd = async (result) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		if (!result.destination) {
			return;
		}
		const splitTaskId = result.destination.droppableId.split('-');

		const taskId = splitTaskId[1];
		const subTaskId = result.draggableId;
		const currentOrderSequence = +result.source.index;
		const newOrderSequence =
			+result.destination.index === 0 ? +result.destination.index + 1 : +result.destination.index;

		const subTaskCopy = [...subTasks];
		var replaceElement = {};

		subTaskCopy.map((item, i) => {
			if (item._id === subTaskId) {
				const [removed] = subTaskCopy.splice(i, 1);
				replaceElement = {
					_id: removed._id,
					updatedAt: removed.updatedAt,
					title: removed.title,
					task_id: removed.task_id,
					orderSequence: newOrderSequence,
					isCompleted: removed.isCompleted,
				};
			}
		});

		if (newOrderSequence < currentOrderSequence) {
			for (let index = 0; index < subTaskCopy.length; index++) {
				if (
					subTaskCopy[index].orderSequence >= newOrderSequence &&
					subTaskCopy[index].orderSequence < currentOrderSequence
				) {
					subTaskCopy[index].orderSequence = +subTaskCopy[index].orderSequence + 1;
				}
			}
		} else {
			for (let index = 0; index < subTaskCopy.length; index++) {
				if (
					subTaskCopy[index].orderSequence > currentOrderSequence &&
					subTaskCopy[index].orderSequence <= newOrderSequence
				) {
					subTaskCopy[index].orderSequence = +subTaskCopy[index].orderSequence - 1;
				}
			}
		}

		subTaskCopy.splice(0, 0, replaceElement);
		subTaskCopy.sort((a, b) => {
			var keyA = a.orderSequence;
			var keyB = b.orderSequence;
			// *Compare
			if (keyA < keyB) return -1;
			if (keyA > keyB) return 1;
			return 0;
		});
		setSubTasks(subTaskCopy);

		const obj = {
			taskId,
			subtaskId: subTaskId,
			newOrderSequence,
		};

		const token = localStorage.getItem('jwt');
		const { status } = await Service.swapSubTask(obj, token);

		if (status === true) {
			getUserSubTaskByTaskId(taskId);
		}
	};

	const getUserSubTaskByTaskId = async (taskId) => {
		const token = localStorage.getItem('jwt');
		const { data } = await Service.getUserSubTaskByTaskId(taskId, token);
		setSubTasks([...data]);
		setAddSubTaskHandle(false);
	};

	const convertToMilliseconds = (dateObj) => {
		try {
			const { day, hour, minute, month, year, ampm } = dateObj;
			const adjustedHour = ampm == 'PM' && hour != 12 ? parseInt(hour) + 12 : parseInt(hour);
			const dateValue = moment({
				year,
				month: month - 1,
				day,
				hour: adjustedHour,
				minute,
			});
			return dateValue.valueOf();
		} catch (error) {
			console.log(error);
		}
	};

	// *For Add Sub Task
	const addSubTask = async (e, id) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		try {
			if (e.target.value) {
				const token = localStorage.getItem('jwt');
				const obj = {
					task_id: id,
					title: e.target.value,
				};
				const { message } = await Service.addSubTask(obj, token);
				getUserSubTaskByTaskId(item._id);
				getTask();
			} else {
				setAddSubTaskHandle(false);
			}
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		}
	};

	const editSubTask = async (id, value) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		try {
			const token = localStorage.getItem('jwt');
			const obj = {
				id,
				...value,
			};
			const { message } = await Service.editSubTask(obj, token);
			getUserSubTaskByTaskId(item._id);
			getTask();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		}
	};

	const enterHandler = async (e) => {
		try {
			if (e.key === 'Enter') {
				textInput.current.blur();
			}
		} catch (error) {
			console.log('file: ListItem.js => line 170 => enterHandler => error', error);
		}
	};

	// *For Task Complete
	const taskComplete = async (subTaskId, isComplete) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		setDisabled(true);
		try {
			const token = localStorage.getItem('jwt');
			const status = isComplete !== true;
			const obj = {
				id: subTaskId,
				status,
			};
			const { message } = await Service.checkUncheckSubtask(obj, token);

			getUserSubTaskByTaskId(item._id);
			getTask();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		} finally {
			setDisabled(false);
		}
	};

	// *For Delete Sub Task
	const deleteSubTask = async (ID) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		try {
			const token = localStorage.getItem('jwt');
			const obj = {
				id: ID,
			};
			const { message } = await Service.deleteSubTask(obj, token);
			getUserSubTaskByTaskId(item._id);
			getTask();
		} catch (error) {
			console.log('file: subtask.js => line 31 => deleteSubTask => error', error);
		}
	};

	// Reminder
	const addReminderTask = async (date, id) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		const obj = {
			subtaskId: id,
			reminderAt: convertToMilliseconds(date),
		};
		try {
			const token = localStorage.getItem('jwt');
			const { message } = await Service.addReminder(obj, token);
			getUserSubTaskByTaskId(item._id);
			getTask();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		}
	};

	const editReminderTask = async (date, id) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		const obj = {
			id,
			reminderAt: convertToMilliseconds(date),
		};
		try {
			const token = localStorage.getItem('jwt');
			const { message } = await Service.editReminder(obj, token);
			getUserSubTaskByTaskId(item._id);
			getTask();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
			getUserSubTaskByTaskId(item._id);
			getTask();
		}
	};

	const deleteReminderTask = async (id) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		try {
			const token = localStorage.getItem('jwt');
			const { message } = await Service.deleteReminder({ id }, token);
			getUserSubTaskByTaskId(item._id);
			getTask();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
		}
	};
	//

	const textBoxFocus = () => {
		setAddSubTaskHandle(true);
		setTimeout(() => {
			textInput.current.focus();
		}, 100);
	};

	useEffect(() => {
		setSubTasks([...subTask]);
		if (item.title) {
			setValue('title', item.title, { shouldValidate: false, shouldTouch: false });
		}
	}, [subTask]);

	useEffect(() => {
		if (item.title && editEnable) {
			setValue('title', item.title, { shouldValidate: false, shouldTouch: false });
		}
	}, [editEnable]);

	const editTitleRequest = async (value) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		if (editEnable) {
			try {
				const obj = {
					id: item._id,
					title: value.title,
					color: value.color,
				};
				await editTaskList(obj);
				setEditEnable(false);
			} catch (error) {
				console.log(error);
				reset();
				setEditEnable(false);
			}
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSubmit(editTitleRequest);
		}
	};

	return (
		<Draggable draggableId={item._id} index={index}>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					{/* ========== Add Task Dialog ========== */}
					{/* <AddSubTask open={openAddSubTask} id={item._id} onClose={() => { subTaskDialog(false) }} addSubTask={addSubTask} /> */}
					<form className="edit-task-form" onSubmit={handleSubmit(editTitleRequest)} ref={formRef}>
						<Grid className="task-box" item md={12} style={{ borderColor: `${item.color[0]?.code}a6` }}>
							<div
								className="header mission-header"
								{...provided.dragHandleProps}
								style={{ backgroundColor: item.color[0]?.code }}
							>
								<Grid container spacing={1} justify="space-between" alignItems="center">
									<Grid
										item
										md={10}
										onClick={() => {
											if (authStore.user && authStore.isExpiredSubscription()) {
												paymentStore.onShowMessageDialog();
												return;
											}
											truncateHandler(item._id);
										}}
									>
										<input
											className={`input-task-title input-edit-title ${!editEnable ? 'input-disable-pointer' : ''} ${textTruncate === item._id ? '' : 'text-truncate'}`}
											placeholder="List Title..."
											{...register('title', {
												required: 'Title is required',
											})}
											draggable="false"
											defaultValue={item.title}
											disabled={!editEnable}
											id={`input-edit_${index}`}
											onKeyDown={(e) => handleKeyDown(e)}
											onBlur={() => handleSubmit(editTitleRequest)()}
										/>
										{errors?.title?.message && editEnable && (
											<p className="error error-edit-title">{errors?.title?.message}</p>
										)}
									</Grid>
									<Grid item md={2}>
										<IconButton
											className="mission-header-icon-more"
											aria-label="menu"
											size="small"
											onClick={(e) => {
												if (authStore.user && authStore.isExpiredSubscription()) {
													paymentStore.onShowMessageDialog();
													return;
												}
												menuHandler(index, e);
											}}
										>
											<More color="black" />
										</IconButton>
										{/* ========== Menu Options ========== */}
										<Menu
											className="menu-option"
											anchorEl={anchorEl && anchorEl[index]}
											keepMounted
											open={anchorEl && Boolean(anchorEl[index])}
											onClose={handleClose}
											getContentAnchorEl={null}
											anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
											transformOrigin={{ vertical: 'top', horizontal: 'right' }}
											classes={{
												list: 'menu-list-misstion',
												paper: 'menu-paper-misstion',
											}}
										>
											<label
												className="option-wrapper option-wrapper-action"
												htmlFor={`input-edit_${index}`}
												onClick={() => {
													if (authStore.user && authStore.isExpiredSubscription()) {
														paymentStore.onShowMessageDialog();
														return;
													}
													handleClose();
													setEditEnable(true);
												}}
											>
												<img src={images.editIcon} alt="drag dot" width="20px" height="20px" />
												<div className="menu-option-label">Edit</div>
											</label>
											<label
												className="option-wrapper option-wrapper-action"
												onClick={() => {
													if (authStore.user && authStore.isExpiredSubscription()) {
														paymentStore.onShowMessageDialog();
														return;
													}
													deleteTaskDialog(true, item._id);
													handleClose();
												}}
											>
												<img
													src={images.deleteIcon}
													alt="drag dot"
													width="18px"
													height="18px"
												/>
												<div className="menu-option-label">Delete</div>
											</label>
											<label
												className="option-wrapper option-wrapper-action"
												onClick={() => {
													if (authStore.user && authStore.isExpiredSubscription()) {
														paymentStore.onShowMessageDialog();
														return;
													}
													clearTasksDialog(item._id);
													handleClose();
												}}
											>
												<img
													src={images.completedIcon}
													alt="drag dot"
													width="18px"
													height="18px"
												/>
												<div className="menu-option-label">Clear Completed Tasks</div>
											</label>
										</Menu>
									</Grid>
								</Grid>
							</div>
							<DragDropContext onDragEnd={onDragEnd}>
								<div className="content content-sub-tasks">
									{subTasks.map((subTask, index) => (
										<SubTask
											taskComplete={taskComplete}
											disabled={disabled}
											deleteSubTask={deleteSubTask}
											addReminderTask={addReminderTask}
											elements={subTask}
											index={subTask.orderSequence}
											key={subTask._id}
											prefix={`${subTask._id}-${subTask.task_id}`}
											editReminderTask={editReminderTask}
											deleteReminderTask={deleteReminderTask}
											editSubTask={editSubTask}
										/>
									))}

									{addSubTaskHandle && (
										<div className="task">
											<input
												type="text"
												ref={textInput}
												placeholder="Add a new task"
												className="input-add-sub-task"
												onBlur={(e) => {
													addSubTask(e, item._id);
												}}
												onKeyPress={(e) => {
													enterHandler(e);
												}}
											/>
										</div>
									)}
									<div
										disabled
										className="add-subtask cursor-pointer"
										onClick={() => {
											if (authStore.user && authStore.isExpiredSubscription()) {
												paymentStore.onShowMessageDialog();
												return;
											}
											textBoxFocus();
										}}
									>
										<div className="add-subtask-wrapper">
											<img src={images.addIcon} alt="add icon" width="24px" height="24px" />
											<div className="add-subtask-title">New Task</div>
										</div>
									</div>
								</div>
							</DragDropContext>
						</Grid>
						{taskColor.length > 0 && editEnable && (
							<div className="task-color-container task-color-edit-container">
								<div className="task-color-title">List Color</div>
								<div className="task-color list-task-color">
									{taskColor.map((color, index) => (
										<label key={index} className="radio-container">
											<input
												type="radio"
												name="radio"
												defaultChecked={color._id == item.color[0]._id}
												value={color._id}
												{...register('color')}
											/>
											<span className="checkmark" style={{ backgroundColor: color.code }} />
										</label>
									))}
								</div>
							</div>
						)}
					</form>
				</div>
			)}
		</Draggable>
	);
}

export default observer(ListItem);
