import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { Service } from '../../../config/service';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';

const PaymentForm = ({ priceId, returnUrl, onCallBack }) => {
	const [isLoading, setIsLoading] = useState(true);
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();
	const elements = useElements();

	const stripe = useStripe();
	const onHandlePayNow = async () => {
		if (!stripe) {
			return;
		}

		const { error: submitError } = await elements.submit();
		if (submitError) {
			return;
		}

		onCallBack({
			isProcessing: true,
		});

		try {
			const token = localStorage.getItem('jwt');
			const rest = await Service.createPaymentIntent(
				{
					price_id: priceId,
				},
				token
			);
			const { client_secret } = rest.data;

			const { error } = await stripe.confirmPayment({
				elements,
				clientSecret: client_secret,
				confirmParams: {
					return_url: returnUrl,
				},
			});

			if (error) {
				toast.error(error?.message, {
					position: 'top-center',
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					theme: 'colored',
					progress: undefined,
				});
				onCallBack({
					isProcessing: false,
					status: false,
				});
				return;
			}
			onCallBack({
				isProcessing: false,
				status: true,
			});
		} catch (e) {
			onCallBack({
				isProcessing: false,
				status: false,
			});
			toast.error(e, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		}
	};

	return (
		<form className="flex flex-col justify-center !p-6" onSubmit={handleSubmit(onHandlePayNow)}>
			{isLoading && (
				<div className="form-setting-payment-first-loading">
					<Skeleton variant="rounded" width="100%" height={60} />
					<Skeleton variant="rounded" width="100%" height={60} />
					<Skeleton variant="rounded" width="100%" height={60} />
				</div>
			)}
			<PaymentElement onLoaderStart={() => setIsLoading(false)} />
			<div className="flex justify-center">
				<button
					type="submit"
					disabled={!stripe}
					className="button-raised !mt-6 md:!mt-[39px] h-[45px] max-w-[298px] uppercase"
				>
					PAY NOW
				</button>
			</div>
		</form>
	);
};

export default PaymentForm;
