import { Box, Dialog, DialogContent, Grid } from '@material-ui/core';
import Navigation from './navigation';
import Header from './header';
import React, { useEffect } from 'react';
import Toaster from '../components/toaster';
import PaymentDialog from '../components/payment/PaymentDialog';
import AddPaymentDialogDialog from '../components/payment/AddPaymentMethodDialog';
import { useLocation } from 'react-router-dom';
import CheckedIcon from '../assets/images/icons/CheckedIcon';
import { loadStripe } from '@stripe/stripe-js';
import { useStore } from '../context/AppContext';
import { observer } from 'mobx-react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const MainLayout = ({ children }) => {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const paymentIntent = queryParams.get('payment_intent');
	const paymentIntentClientSecret = queryParams.get('payment_intent_client_secret');
	const redirectStatus = queryParams.get('redirect_status');
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const { authStore, paymentStore } = useStore();

	const onHandleClosePaymentDialog = () => {
		// if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
		// 	return;
		// }
		paymentStore.setShowPaymentDialog(false);
	};

	const onHandleCloseAddPaymentDialog = () => {
		// if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
		// 	return;
		// }
		paymentStore.setAddShowPaymentDialog(false);
		paymentStore.setShowPaymentDialog(true);
	};

	const onHandleSubmitAddPaymentMethod = () => {
		paymentStore
			.getCurrentPaymentMethod()
			.then()
			.finally(() => {
				paymentStore.setAddShowPaymentDialog(false);
				paymentStore.setShowPaymentDialog(true);
			});
	};

	const onHandleChangePaymentMethod = () => {
		paymentStore.setAddShowPaymentDialog(true);
		onHandleClosePaymentDialog();
	};

	const checkPaymentIntentStatus = async (clientSecret) => {
		const stripe = await stripePromise;

		if (stripe && clientSecret) {
			setOpen(true);
			const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);
			if (error) {
				setMessage('Payment Failed!');
			} else {
				if (paymentIntent.status === 'succeeded') {
					setMessage('Payment Success!');
				} else if (paymentIntent.status === 'failed') {
					setMessage('Payment Failed!');
				}
			}
		}
	};

	useEffect(() => {
		if (authStore.user && !authStore.user.subscription) {
			window.location.replace('/payment');
		}
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
		}
	}, [authStore.user]);

	useEffect(() => {
		if (paymentIntent && paymentIntentClientSecret && redirectStatus) {
			paymentStore.setShowPaymentDialog(false);
			paymentStore.setAddShowPaymentDialog(false);
			checkPaymentIntentStatus(paymentIntentClientSecret).then();
		}
	}, []);

	return (
		<Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start">
			<PaymentDialog
				paymentMethod={paymentStore?.paymentInfo}
				priceInfo={paymentStore?.priceInfo}
				paymentStatus={paymentStore?.paymentStatus}
				open={paymentStore?.showPaymentDialog}
				onClose={onHandleClosePaymentDialog}
				onChangePaymentMethod={onHandleChangePaymentMethod}
			/>
			<AddPaymentDialogDialog
				paymentMethod={paymentStore?.paymentInfo}
				open={paymentStore?.showAddPaymentDialog}
				onClose={onHandleCloseAddPaymentDialog}
				onSubmit={onHandleSubmitAddPaymentMethod}
			/>
			<Toaster />

			{/* ========== Navigation ========== */}
			<Navigation />

			{/* ========== Main Content ========== */}
			<Grid id="MainContent" container spacing={0} item md={10}>
				{/* ========== Header ========== */}
				<Header />

				{/* ========== Settings ========== */}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						{children}
					</Box>
				</Grid>
			</Grid>

			<Dialog
				open={open}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<div className="flex flex-col items-center">
						<CheckedIcon />
						<p className="text-primary mt-4 text-[18px] font-black">{message}</p>
						<button
							onClick={() => window.location.replace('/')}
							className="button-raised !mt-6 md:!mt-[38px] h-[45px] max-w-[298px] uppercase"
						>
							BACK TO HOME
						</button>
					</div>
				</DialogContent>
			</Dialog>
		</Grid>
	);
};

export default observer(MainLayout);
