import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { Service } from '../../config/service';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';

const FormCard = ({ onCallBack }) => {
	const [isLoading, setIsLoading] = useState(true);
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();
	const stripe = useStripe();
	const elements = useElements();

	const changePayment = async () => {
		if (!stripe) {
			return;
		}

		const { error: submitError } = await elements.submit();
		if (submitError) {
			return;
		}

		onCallBack({
			isProcessing: true,
		});
		try {
			const token = localStorage.getItem('jwt');
			const rest = await Service.setupPayment(
				{
					payment_method_types: ['card', 'au_becs_debit'],
				},
				token
			);

			const { client_secret: clientSecret } = rest.data;

			const { error } = await stripe.confirmSetup({
				elements,
				clientSecret,
				// confirmParams: {
				// 	return_url: `${window.location.origin}/settings`,
				// },
				redirect: 'if_required',
			});

			if (error) {
				toast.error(error?.message, {
					position: 'top-center',
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					theme: 'colored',
					progress: undefined,
				});
				onCallBack({
					isProcessing: false,
					status: false,
				});
			}
			onCallBack({
				isProcessing: false,
				status: true,
			});
		} catch (e) {
			console.error(e);
			toast.error(e, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
			onCallBack({
				isProcessing: false,
				status: false,
			});
		}
	};
	return (
		<form onSubmit={handleSubmit(changePayment)}>
			{isLoading && (
				<div className="form-setting-payment-first-loading">
					<Skeleton variant="rounded" width="100%" height={60} />
					<Skeleton variant="rounded" width="100%" height={60} />
					<Skeleton variant="rounded" width="100%" height={60} />
				</div>
			)}
			<PaymentElement onLoaderStart={() => setIsLoading(false)} />
			<button
				type="submit"
				className="button-raised btn-save-payment uppercase h-[45px] !mt-6 !mx-0"
				disabled={!stripe}
			>
				Save
			</button>
		</form>
	);
};

export default FormCard;
