import { makeAutoObservable } from 'mobx';
import { Service } from '../config/service';
import authStore from './AuthStore';

class PaymentStore {
	showPaymentDialog = false;
	showAddPaymentDialog = false;
	showMessageDialog = false;
	priceInfo = null;
	paymentInfo = null;
	paymentStatus = '';

	constructor() {
		makeAutoObservable(this);
	}

	setShowPaymentDialog(open) {
		this.showPaymentDialog = open;
	}

	setAddShowPaymentDialog(open) {
		this.showAddPaymentDialog = open;
	}

	setShowMessageDialog(open) {
		this.showMessageDialog = open;
	}

	async getCurrentPaymentMethod() {
		try {
			const token = localStorage.getItem('jwt');
			const { data } = await Service.getPaymentMethod(token);
			if (data && data.type) {
				this.paymentInfo = {
					id: data?._id,
					stripe_payment_method_id: data?.stripe_payment_method_id,
					type: data?.type || '',
					last4: data?.card?.last4 || '',
					brand: data?.card?.brand || '',
					display_brand: data?.card?.display_brand || '',
				};
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getPlan() {
		try {
			const token = localStorage.getItem('jwt');
			const { data } = await Service.getPlan(token);
			if (data && data.length > 0) {
				const product = data[0];
				this.priceInfo = product?.prices[0];
			}
		} catch (error) {
			console.log(error);
		}
	}

	onShowMessageDialog() {
		console.log('Donald....');
		this.getCurrentPaymentMethod().then();
		this.getPlan().then();
		this.setShowPaymentDialog(true);
		this.paymentStatus = authStore.statusPayment();
	}
}

export default new PaymentStore();
