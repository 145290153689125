import React from 'react';

function MasterCardIcon({ width = 23, height = 16 }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox={`0 0 ${width} ${height}`}
		>
			<rect width="22" height="15" x="0.5" y="0.5" fill="#fff" stroke="#D9D9D9" rx="2"></rect>
			<path
				fill="#ED0006"
				fillRule="evenodd"
				d="M11.714 11.697a5.056 5.056 0 01-3.26 1.181c-2.775 0-5.025-2.213-5.025-4.943 0-2.73 2.25-4.943 5.025-4.943 1.244 0 2.383.445 3.26 1.181a5.056 5.056 0 013.26-1.18C17.75 2.992 20 5.204 20 7.934s-2.25 4.943-5.026 4.943a5.056 5.056 0 01-3.26-1.18z"
				clipRule="evenodd"
			></path>
			<path
				fill="#F9A000"
				fillRule="evenodd"
				d="M11.714 11.697a4.9 4.9 0 001.766-3.762 4.9 4.9 0 00-1.766-3.762 5.056 5.056 0 013.26-1.18C17.75 2.992 20 5.204 20 7.934s-2.25 4.943-5.026 4.943a5.056 5.056 0 01-3.26-1.18z"
				clipRule="evenodd"
			></path>
			<path
				fill="#FF5E00"
				fillRule="evenodd"
				d="M11.714 11.697a4.899 4.899 0 001.766-3.762 4.9 4.9 0 00-1.766-3.762A4.9 4.9 0 009.95 7.935c0 1.507.685 2.856 1.765 3.762z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

export default MasterCardIcon;
