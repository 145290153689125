import tokenStore from './TokenStore';
import authStore from './AuthStore';
import paymentStore from './PaymentStore';

export default class AppStore {
	tokenStore = tokenStore;

	authStore = authStore;

	paymentStore = paymentStore;
}
