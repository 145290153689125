import React, { useState } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { Divider } from '@mui/material';
import { Logo } from '../../assets/images/icons';
import AddCircleIcon from '../../assets/images/icons/AddCircleIcon';
import MasterCardIcon from '../../assets/images/icons/MasterCardIcon';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FormCheckout from './FormCheckout';
import { useLocation } from 'react-router-dom';
import WarningIcon from '../../assets/images/icons/WarningIcon';
import { convertToCurrency } from '../../utils';
import CloseGreenIcon from '../../assets/images/icons/CloseGreenIcon';
import { useStore } from '../../context/AppContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentDialog = ({ open, onClose, paymentMethod, priceInfo, paymentStatus, onChangePaymentMethod }) => {
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [showErrorDialog, setShowErrorDialog] = useState(false);
	const { paymentStore } = useStore();
	const options = {
		appearance: {
			theme: 'stripe',
			variables: {
				colorPrimary: '#419488',
				colorBackground: '#ffffff',
				colorText: '#30313d',
				colorDanger: '#df1b41',
				fontFamily: 'Ideal Sans, system-ui, sans-serif',
				borderRadius: '4px',
			},
		},
		layout: {
			type: 'tabs',
			defaultCollapsed: false,
		},
	};
	const location = useLocation();

	const onHandleCallBack = (data) => {
		if (Object.hasOwn(data, 'isProcessing')) {
			setLoadingSubmit(data.isProcessing);
		}
		if (Object.hasOwn(data, 'status')) {
			if (!data.status) {
				paymentStore.setShowPaymentDialog(false);
				setShowErrorDialog(true);
			}
		}
	};

	return (
		<>
			<Dialog
				key={paymentMethod?.id}
				open={open}
				onClose={onClose}
				classes={{
					paper: 'payment-dialog',
				}}
			>
				<DialogContent>
					<div className="flex flex-col items-center px-[10px]">
						<div className="flex flex-col items-center gap-[11px]">
							<WarningIcon />
							{paymentStatus !== 'succeeded' ? (
								<>
									<p className="text-primary text-[18px] font-black text-start mb-[24px]">
										Your subscription has expired and there was an error processing your payment
									</p>
								</>
							) : (
								<>
									<p className="text-primary text-[18px] font-black text-start mb-[24px]">
										Your subscription has expired. Please renew to continue using all features.
									</p>
								</>
							)}
						</div>

						<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
							<div className="col-span-1 flex flex-col gap-[22px]">
								{paymentStatus !== 'succeeded' && (
									<p className="text-second text-[14px] font-normal">
										This could be due to payment method not having sufficient funds or an error with
										your current payment method, Kindly input your payment method to continue.
									</p>
								)}
								<div className="card subscription flex flex-col gap-2 text-[15px]">
									<div className="flex flex-row gap-[7px] items-center">
										<Logo />
										<p className="text-primary font-extrabold leading-[32px]">Subscription</p>
									</div>
									<div className="flex flex-row justify-between items-center font-medium text-second">
										<p className="leading-[32px]">Subtotal</p>
										<p className="leading-[32px]">{`${convertToCurrency(priceInfo)} billed monthly`}</p>
									</div>
									<Divider />
									<div className="flex flex-row justify-between items-center text-primary font-medium">
										<p className="leading-[32px]">Total</p>
										<p className="leading-[32px] font-extrabold">{convertToCurrency(priceInfo)}</p>
									</div>
								</div>
							</div>
							<div className="col-span-1">
								<div className="card !p-4">
									{!paymentMethod ? (
										<>
											<p className="text-primary font-extrabold leading-[32px]">Payment</p>
											<button className="flex items-center gap-2" onClick={onChangePaymentMethod}>
												<AddCircleIcon color="#7AC8C2" />
												<p className="text-[#7AC8C2] text-[14px] leading-[32px] font-bold">
													Add Payment Method
												</p>
											</button>
										</>
									) : (
										<>
											<p className="text-primary font-extrabold mb-2">Pay with:</p>
											<div className="flex justify-between items-center">
												<div className="flex flex-row items-center gap-2">
													<MasterCardIcon />
													<p className="text-second text-[12px] font-medium first-letter:uppercase">
														{paymentMethod?.display_brand || ''}
													</p>
													<p className="text-second text-[12px] font-medium">
														XXXX XXXX XXXX {paymentMethod?.last4 || ''}
													</p>
												</div>
												<button
													className="text-[#7AC8C2] text-[14px] leading-[32px] font-bold"
													onClick={onChangePaymentMethod}
												>
													Change
												</button>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<Elements stripe={stripePromise} options={options}>
							<FormCheckout
								paymentMethod={paymentMethod}
								priceId={priceInfo?._id}
								returnUrl={`${window.location.origin}${location.pathname}`}
								onCallBack={onHandleCallBack}
							/>
						</Elements>
					</div>
				</DialogContent>
			</Dialog>
			<Dialog
				open={loadingSubmit}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<div className="paper-dialog-payment-loading">
						<CircularProgress color="inherit" />
						<div className="paper-dialog-payment-loading-title">Processing Payment... </div>
					</div>
				</DialogContent>
			</Dialog>
			<Dialog
				open={showErrorDialog}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<div className="paper-dialog-payment-loading">
						<CloseGreenIcon />
						<div className="paper-dialog-payment-loading-title">
							Oops! There was an error <br /> processing your payment
						</div>
						<button
							onClick={() => {
								paymentStore.setShowPaymentDialog(true);
								setShowErrorDialog(false);
							}}
							className="button-raised !mt-6 md:!mt-[38px] h-[45px] max-w-[298px] uppercase"
						>
							TRY AGAIN
						</button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PaymentDialog;
