import React from 'react';
import { useForm } from 'react-hook-form';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { Service } from '../../config/service';

const FormCheckout = ({ paymentMethod, priceId, returnUrl, onCallBack }) => {
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();
	const stripe = useStripe();
	const onHandlePayNow = async () => {
		if (!stripe) {
			return;
		}

		onCallBack({
			isProcessing: true,
		});

		try {
			const token = localStorage.getItem('jwt');
			const rest = await Service.createPaymentIntent(
				{
					payment_method_id: paymentMethod?.id,
					price_id: priceId,
				},
				token
			);
			const { client_secret } = rest.data;
			const { error } = await stripe.confirmPayment({
				clientSecret: client_secret,
				confirmParams: {
					return_url: returnUrl,
				},
				payment_method: paymentMethod?.stripe_payment_method_id,
			});

			if (error) {
				// toast.error(error?.message, {
				// 	position: 'top-center',
				// 	autoClose: 2000,
				// 	hideProgressBar: true,
				// 	closeOnClick: false,
				// 	pauseOnHover: false,
				// 	draggable: false,
				// 	theme: 'colored',
				// 	progress: undefined,
				// });
				onCallBack({
					isProcessing: false,
					status: false,
				});
				return;
			}
			onCallBack({
				isProcessing: false,
				status: true,
			});
		} catch (e) {
			onCallBack({
				isProcessing: false,
				status: false,
			});
			// toast.error(e, {
			// 	position: 'top-center',
			// 	autoClose: 2000,
			// 	hideProgressBar: true,
			// 	closeOnClick: false,
			// 	pauseOnHover: false,
			// 	draggable: false,
			// 	theme: 'colored',
			// 	progress: undefined,
			// });
		}
	};

	return (
		<form className="w-full flex justify-center" onSubmit={handleSubmit(onHandlePayNow)}>
			<button type="submit" className="button-raised !mt-6 md:!mt-[39px] h-[45px] max-w-[298px] uppercase">
				PAY NOW
			</button>
		</form>
	);
};

export default FormCheckout;
