import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { images } from 'assets/images/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { disabledInspect } from 'utils';
import PaymentForm from './formPayment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function ModalPayment(props) {
	const { open, onClose } = props;
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const options = {
		mode: 'setup',
		// currency: 'usd',
		// automatic_payment_methods: {
		//   enabled: true,
		// },
		appearance: {
			theme: 'stripe',
			variables: {
				colorPrimary: '#419488',
				colorBackground: '#ffffff',
				colorText: '#30313d',
				colorDanger: '#df1b41',
				fontFamily: 'Ideal Sans, system-ui, sans-serif',
				borderRadius: '4px',
			},
			rules: {
				'.Tab': {
					border: '1px solid #D9DDE2',
					boxShadow: 'none',
				},
				'.Tab--selected': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.Tab:focus': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.Tab--selected:focus': {
					border: '1px solid #419488',
					boxShadow: 'none',
				},
				'.TabIcon--selected': {
					fill: '#419488',
				},
				'.TabLabel--selected': {
					color: '#419488',
				},
				'.Input:focus': {
					boxShadow: 'none',
				},
				'.TermsText': {
					display: 'none',
					color: '#419488',
				},
				'.Error': {
					fontSize: '12px',
				},
				'.Input--invalid': {
					boxShadow: 'none',
				},
			},
		},
		layout: {
			type: 'tabs',
			defaultCollapsed: false,
		},
		paymentMethodTypes: ['card', 'au_becs_debit'],
	};

	useEffect(() => {
		disabledInspect();
	}, []);

	//   paymentEnable
	// paymentInfo

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<>
						<div className="dialog-delete-list-content dialog-payment-header">
							<img
								className="dialog-payment-close-icon"
								onClick={() => onClose()}
								src={images.closeIcon}
								alt="icon trash"
								width="24px"
								height="24px"
							/>
							<div className="dialog-delete-list-title dialog-payment-title">Change Payment Method</div>
						</div>
						<Elements stripe={stripePromise} options={options}>
							<PaymentForm
								setLoadingSubmit={setLoadingSubmit}
								paymentEnable={props.paymentEnable}
								paymentInfo={props.paymentInfo}
							/>
						</Elements>
					</>
				</DialogContent>
			</Dialog>
			<Dialog
				open={loadingSubmit}
				disableEscapeKeyDown
				disableBackdropClick
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				classes={{
					paper: 'paper-dialog-delete-task paper-dialog-payment',
				}}
			>
				<DialogContent>
					<div className="paper-dialog-payment-loading">
						<CircularProgress color="inherit" />
						<div className="paper-dialog-payment-loading-title">Processing change payment method...</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}
