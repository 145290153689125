import React from 'react';
import AppRoutes from 'routes';
import AppContextProvider from './context/AppContext';

function App() {
	return (
		<AppContextProvider>
			<AppRoutes />
		</AppContextProvider>
	);
}

export default App;
