import React, { useEffect } from 'react';

import { disabledInspect } from 'utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Toaster() {
	useEffect(() => {
		disabledInspect();
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<ToastContainer
			position="top-center"
			autoClose={2000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick={false}
			rtl={false}
			pauseOnFocusLoss={false}
			draggable={false}
			pauseOnHover={false}
			limit={1}
		/>
	);
}
