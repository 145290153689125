import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Service } from 'config/service';
import { useForm } from 'react-hook-form';
import { Skeleton } from '@mui/material';
import { toast } from 'react-toastify';

const PaymentForm = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const {
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const stripe = useStripe();
	const elements = useElements();

	const changePayment = async () => {
		if (!stripe) {
			return;
		}

		const { error: submitError } = await elements.submit();
		if (submitError) {
			return;
		}

		props.setLoadingSubmit(true);
		try {
			const token = localStorage.getItem('jwt');
			const rest = await Service.setupPayment(
				{
					payment_method_types: ['card', 'au_becs_debit'],
				},
				token
			);

			const { client_secret: clientSecret } = rest.data;

			const { error } = await stripe.confirmSetup({
				elements,
				clientSecret,
				confirmParams: {
					return_url: `${window.location.origin}/settings`,
				},
			});
			if (error) {
				toast.error(error, {
					position: 'top-center',
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					theme: 'colored',

					progress: undefined,
				});
				props.setLoadingSubmit(false);
			}
			props.setLoadingSubmit(false);
		} catch (e) {
			console.log(e);
			toast.error(e, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
			props.setLoadingSubmit(false);
		}
	};

	return (
		<form className="form-setting-payment" onSubmit={handleSubmit(changePayment)}>
			{isLoading && (
				<div className="form-setting-payment-first-loading">
					<Skeleton variant="rounded" width="100%" height={60} />
					<Skeleton variant="rounded" width="100%" height={60} />
					<Skeleton variant="rounded" width="100%" height={60} />
				</div>
			)}
			{!isLoading && props.paymentEnable && (
				<>
					<div className="form-setting-payment-current">
						<div className="form-setting-payment-current-title">Current:</div>
						<div className="form-setting-payment-current-info">
							<p className="form-setting-payment-current-brand">
								{props.paymentInfo?.display_brand || ''}
							</p>
							<p className="form-setting-payment-current-number">
								XXXX XXXX XXXX {props.paymentInfo?.last4 || ''}
							</p>
						</div>
					</div>
					<div className="form-setting-payment-current-new">New Payment Method:</div>
				</>
			)}
			<PaymentElement onLoaderStart={() => setIsLoading(false)} />
			<button className="button-raised btn-save-payment" disabled={!stripe}>
				Save
			</button>
		</form>
	);
};

export default PaymentForm;
