import { createContext, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { observer } from 'mobx-react';

import AppStore from '../stores/AppStore';

const AppContext = createContext();

export const useStore = () => {
	const context = useContext(AppContext);
	return context.store;
};

export const useAuth = () => useContext(AppContext);

const AppContextProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const store = useRef(new AppStore());

	const contextValue = useMemo(
		() => ({
			store: store.current,
			isAuthenticated,
			isLoading,
		}),
		[isAuthenticated, isLoading]
	);

	const { tokenStore, authStore } = store.current;

	useEffect(() => {
		setIsLoading(true);
		if (tokenStore.isAuthenticated()) {
			authStore
				.loadUserProfile()
				.then(() => {
					setIsAuthenticated(true);
					setIsLoading(false);
					authStore.setIsAuthenticated(true);
				})
				.catch((err) => {
					console.log(err);
					authStore.logout();
					window.location.replace('/login');
				});
			return;
		}
		setIsAuthenticated(false);
		setIsLoading(false);
		authStore.logout();
	}, [tokenStore.token]);

	return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default observer(AppContextProvider);
