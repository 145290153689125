import React from 'react';
import { Redirect, Route } from 'react-router';
import { useStore } from '../context/AppContext';

export default function PublicRoute({ ...rest }) {
	const { authStore } = useStore();
	if (authStore.isAuthenticated) {
		return <Redirect to="/" />;
	}
	return <Route {...rest} />;
}
