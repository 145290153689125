import { makeAutoObservable, reaction } from 'mobx';
import jwtDecode from 'jwt-decode';

class TokenStore {
	token = null;

	refreshToken = null;

	constructor() {
		makeAutoObservable(this);

		this.token = localStorage.getItem('jwt') || '';
		reaction(
			() => this.token,
			(token) => {
				if (token) {
					localStorage.setItem('jwt', token);
				}
			}
		);
	}

	isAuthenticated() {
		if (!this.token) {
			return false;
		}

		const { exp } = jwtDecode(this.token);
		if (exp * 1000 > Date.now()) {
			return true;
		}

		return false;
	}

	setToken(token) {
		this.token = token;
	}

	logout() {
		this.token = null;
		localStorage.removeItem('jwt');
	}

	getToken() {
		return this.token;
	}
}

export default new TokenStore();
