import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { disabledInspect } from 'utils/index';
import { Service } from 'config/service';

import { Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStore } from '../../context/AppContext';
import { observer } from 'mobx-react';

function EditThought() {
	const { authStore, paymentStore } = useStore();

	const history = useHistory();
	const { id } = useParams();

	// *For Loader
	const [loader, setLoader] = useState(false);

	// *For Get Thought By Id
	const [thought, setThought] = useState([]);

	// *For Form Validation
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	// *Get Thought By Id
	const getThoughtByThoughtId = async () => {
		try {
			const token = localStorage.getItem('jwt');
			const { data } = await Service.getThoughtByThoughtId(id, token);
			setThought(data[0]);

			// *For Default Value
			setValue('title', data[0].title);
			setValue('description', data[0].description);
		} catch (error) {
			console.log('file: edit-thought.js => line 41 => getThoughtByThoughtId => error', error);
		}
	};

	// *For Update Thought
	const update = async (data) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		setLoader(true);
		try {
			const token = localStorage.getItem('jwt');
			const obj = {
				id,
				title: data.title,
				description: data.description,
			};
			const { message } = await Service.editThought(obj, token);
			setTimeout(() => {
				history.push('/my-thoughts');
			}, 1000);
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		getThoughtByThoughtId();
		disabledInspect();
		window.scrollTo({ top: 0 });
	}, [register]);

	return (
		<>
			{/* ========== Thought ========== */}
			<Grid item xs={12} sm={12} md={12} lg={12}>
				{/* ========== Breadcrumbs ========== */}
				<Breadcrumbs aria-label="breadcrumb">
					<Typography className="cursor-pointer" component="p" onClick={() => history.push('/my-thoughts')}>
						My Thoughts
					</Typography>
					<Typography className="text-color" component="p">
						Edit Thought
					</Typography>
				</Breadcrumbs>

				{/* ========== Write Thought ========== */}
				<form className="write-thought" onSubmit={handleSubmit(update)}>
					<Grid container spacing={0} justifyContent="center">
						<Grid item xs={12} sm={12} md={12}>
							<input
								name="title"
								className="title"
								placeholder="Thought Title"
								setValue={thought.title}
								{...register('title', {
									required: 'Title is required',
								})}
							/>
							{errors?.title?.message && <p className="error">{errors?.title?.message}</p>}
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<textarea
								name="description"
								className="description"
								placeholder="Description"
								{...register('description', {
									required: 'Thought is required',
								})}
							/>
							{errors?.description?.message && <p className="error">{errors?.description?.message}</p>}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<button
								type="submit"
								className={`button-raised ${loader === true ? 'spinner button-disabled ' : ''}`}
								disabled={loader === true}
							>
								Save
							</button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</>
	);
}

export default observer(EditThought);
